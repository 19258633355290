import { createRouter, createWebHistory } from 'vue-router';
import LogisticRoute from '../components/LogisticRoutes.vue';

const routes = [
  {
    path: '/',
    name: 'LogisticRoute',
    component: LogisticRoute,
    meta: {
      title: '运盛中越'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;