<template>
  <div class="page-container">
    <div class="card-container">
      <div class="card">
        <div class="search-container">
          <input v-model="trackingNumber" type="text" placeholder="请输入物流单号" class="search-input"/>
          <button @click="search" class="search-button">查询</button>
        </div>
        <div v-if="loading" class="loading">加载中...</div>
        <div v-if="trackingInfo.length > 0" class="timeline">
          <div v-for="(item, index) in trackingInfo" :key="item.id" class="timeline-item">
            <div class="timeline-dot" :class="{ 'latest-dot': index === 0 }"></div>
            <div class="timeline-content">
              <div class="time">{{ item.scanDateTime }}</div>
              <div class="remark">{{ item.remark }}</div>
              <div class="horizontal-line" v-if="index < trackingInfo.length - 1"></div>
            </div>
            <div class="vertical-line" v-if="index < trackingInfo.length - 1"></div>
          </div>
        </div>
        <div v-if="!loading && trackingInfo.length === 0" class="no-data">{{ errorMessage }}</div>
      </div>


      <!-- New bottom information block -->
      <div class="bottom-info">
        <p>请用原单号查件，只显示越南境内快递信息，更多详情联系客服。</p>
        <div class="centered-content">
          <p>电话: 0385636560</p>
          <p>地址: 平阳新城三菱4S店后</p>
          <p><a href="https://beian.miit.gov.cn" target="_blank">豫ICP备2024062518号</a></p>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import axios from 'axios';

axios.defaults.baseURL = "/api";

export default {
  data() {
    return {
      trackingNumber: '',
      trackingInfo: [],
      loading: false,
      errorMessage: '暂无物流信息'
    };
  },
  methods: {
    search() {
      this.loading = true;
      this.errorMessage = '';
      const billNo = this.trackingNumber.replace(/\s/g, '');
      if (billNo.length === 0) {
        this.errorMessage = "物流单号不能为空";
        this.loading = false;
        return;
      }
      axios.get('/logistic/route/list?billNo=' + billNo)
          .then(response => {
            if (response.data.code === 0) {
              this.trackingInfo = response.data.data;
              if (this.trackingInfo === null || this.trackingInfo.length === 0) {
                this.errorMessage = '暂无物流信息';
              }
            } else {
              this.trackingInfo = [];
              this.errorMessage = response.data.msg;
            }
          })
          .catch(error => {
            console.error('Error fetching tracking information:', error);
            this.errorMessage = '查询失败，请稍后重试';
          }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.page-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.bottom-info {
  position: relative;
  width: 100%; /* 确保底部块占据整个容器宽度 */
  max-width: 375px; /* 可选：限制最大宽度 */
  background-color: #fff;
  padding: 15px;
  font-size: 14px;
  color: #777;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: -30px; /* 跟 card-container 对齐，并保持距离为 30px */
}

.bottom-info p {
  margin: 0;
}

.bottom-info a {
  color: #007bff;
  text-decoration: none;
  margin-left: 0;
}

.bottom-info .centered-content {
  text-align: center; /* Align centered content to the center */
  margin: 0;
}

.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* 居中对齐 */
  background-image: linear-gradient(to bottom, #007bff, #ffffff);
  padding-bottom: 30px; /* 保留底部块与卡片容器的间距 */
}

.card {
  width: 375px;
  max-width: 375px;
  height: 82vh;
  /* 设置为100vh */
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  margin: 10px 10px 0;
  /* 调整顶部空间 */
  overflow-y: auto;
}

.loading {
  text-align: center;
  margin-top: 20px;
}

.search-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ced4da;
  font-size: 14px;
}

.search-button {
  padding: 10px 20px;
  border-radius: 0 5px 5px 0;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.search-button:hover {
  background-color: #0056b3;
}

.timeline {
  width: 100%;
}

.timeline-item {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.timeline-content {
  flex: 1;
}

.time,
.remark {
  font-size: 14px;
  color: #777;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  align-items: flex-start;
  /* 左对齐 */
  text-align: left;
  /* 文本左对齐 */
}

.timeline-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background: linear-gradient(45deg, #6c757d, #343a40);
}

.latest-dot::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
}

.latest-dot {
  background: linear-gradient(45deg, #28a745, #007bff);
  animation: pulse 1.5s infinite alternate;
}

.vertical-line {
  position: absolute;
  left: 5px;
  width: 0.13rem;
  height: calc(100% - 10px);
  /* 调整高度，与点无缝连接 */
  background-color: rgba(204, 204, 204, 0.5);
  bottom: -10px;
  /* 调整位置，与点无缝连接 */
}

.horizontal-line {
  position: absolute;
  left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  background: linear-gradient(to right, rgba(204, 204, 204, 0.1), rgba(204, 204, 204, 0.5), rgba(204, 204, 204, 0.1));
  bottom: -6px;
}

.no-data {
  text-align: center;
  margin-top: 20px;
  color: #777;
  font-size: 14px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
  }

  100% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
  }
}
</style>